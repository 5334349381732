<template>
  <div>
    <PricesHeaderMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></PricesHeaderMobile>
    <PricesHeader v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'" ></PricesHeader>

    <PriceCalculatorMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></PriceCalculatorMobile>
    <PriceCalculator v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'" ></PriceCalculator>

    <FastPayoutMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></FastPayoutMobile>
    <FastPayout v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'" ></FastPayout>

    <PricesBenefitsMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></PricesBenefitsMobile>
    <PricesBenefits v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'"></PricesBenefits>

    <FeatureForm class="px-8"></FeatureForm>

    <PersonalSupportMobile v-if="getDisplaySize() === 'sm' || getDisplaySize() === 'xs' "></PersonalSupportMobile>
    <PersonalSupport v-if="getDisplaySize() !== 'sm' && getDisplaySize() !== 'xs'"></PersonalSupport>

    <div class="my-16 py-4"></div>
  </div>
</template>

<script>
const PricesHeader = () => import('../components/PricesHeader.vue');
const PriceCalculator = () => import('../components/PricesCalculator.vue');
const FastPayout = () => import('../components/FastPayout.vue');
const PricesBenefits = () => import('../components/PricesBenefits.vue');
const FeatureForm = () => import('../components/FeatureForm.vue');
const PersonalSupport = () => import('../components/PersonalSupport.vue');
const PersonalSupportMobile = () => import('../components/PersonalSupportMobile.vue');
const PricesBenefitsMobile = () => import('../components/PricesBenefitsMobile.vue');
const FastPayoutMobile = () => import('../components/FastPayoutMobile.vue');
const PriceCalculatorMobile = () => import('../components/PricesCalculatorMobile.vue');
const PricesHeaderMobile = () => import('../components/PricesHeaderMobile.vue');

export default {
  name: "Prices",
  components: {
    PricesHeader,
    PriceCalculator,
    FastPayout,
    PricesBenefits,
    FeatureForm,
    PersonalSupport,
    PersonalSupportMobile,
    PricesBenefitsMobile,
    FastPayoutMobile,
    PriceCalculatorMobile,
    PricesHeaderMobile
  },
  methods: {
    getDisplaySize(){
      return this.$vuetify.breakpoint.name;
    },
  }
}
</script>

<style scoped>

</style>